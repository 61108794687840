export default {
  home: {
    buy: "Buy",
    title: ["BRC20 Launchpad", "Inscription Liquidity Aggregation Platform"],
    voting: "Voting",
    vote: "Vote",
    onMarket: "Listed",
    projectDetail: "Project Details",
    title2: [
      "KOL Certification and Airdrop Rewards",
      "Launch and Promote Projects"
    ],
    desc2: [
      "Submit KOL certification by having an active Twitter or other social media account and staking at least 2100 SOS;",
      "After passing KOL certification, you can initiate project votes or select listing projects, promoting them to earn token airdrop rewards;",
      "Token airdrop allocation weights are calculated based on Twitter follower count, engagement with certified tweets (views, comments, likes, and retweets), actual SOS staked, and considering Telegram and Discord follower counts and activity levels."
    ],
    kolBtn: "KOL Certification",
    startProject: "Launch Project",
    review: "Review",
    dispositNumber: "Staked Amount",
    dispositDesc:
      "Unstaking SOS will immediately terminate your KOL certification, and reapplication will not be allowed. Please proceed with caution.",
    liftDisposit: "Unstake",
    title3: [
      "Real-time Equivalent Cross-chain Exchange",
      "SOS Now Supports SATS, ORDI, SBTC..."
    ],
    navTitle3: [
      "Hold SOS to Enjoy Inscription Airdrops,",
      "Stake SOS to Earn Project Rewards."
    ],
    desc3: [
      "50% of the total SOS is issued in the cross-chain exchange contract, supporting early fair-minted inscriptions like sats, ordi, and SBTC, enabling real-time equivalent exchange; ",
      "30% of the total SOS is issued in the liquidity pool (LP) exchange contract. Adding liquidity to the SOS LP will grant equivalent SOS and activate eligibility for inscription airdrops, allowing regular participation in inscription airdrops; ",
      "10% of the total SOS is issued in the KOL linear unlock contract. Certified KOLs participating in community building will receive SOS rewards periodically based on cross-chain exchange progress, LP addition progress, and certified KOL weight;",
      "10% of the total SOS is reserved in the launch pool, used to create SOS-BTCB, SOS-BNB, and SOS-USDT base LPs, and to provide liquidity aggregation bridges for projects created on SmartBTC.io.",
      "SmartBTC regularly uses website revenue and project airdrop proceeds to repurchase SOS and transfer it to the cross-chain bridge contract, continuously activating cross-chain quotas until 100%. Once fully activated, bidirectional cross-chain exchange will take effect simultaneously."
    ],
    title4: "Technical Roadmap",
    navTitle4:
      "BTC asset issued fairly, integrated with BSC cross-chain DeFi applications, gradually compatible with mainstream BTC L2...",
    desc4: [
      {
        time: "March 9, 2023",
        desc: "Deployment of the first SOS inscription with BTC characters using the Ordinals protocol."
      },
      {
        time: "May 12, 2023",
        desc: "The total supply of 21 million SOS was minted in batches of 500, involving nearly 2000 addresses."
      },
      {
        time: "January 13, 2024",
        desc: "SmartBTC, a VC DAO focused on the Bitcoin ecosystem, was jointly announced at the Hong Kong Bitcoin Ecosystem Summit hosted by several well-known Chinese Web3 brands."
      },
      {
        time: "April 15, 2024",
        desc: "Acquisition of CoinDAO, which has over 10,000 wallet addresses. Community tokens 100T can be swapped at a 10:1 ratio for BSC mining reward tokens 100T, migrating to SmartBTCdao, and providing basic liquidity for the new BRC852 asset."
      },
      {
        time: "April 26, 2024",
        desc: "The SmartBTC.io mainnet was officially launched, opening the first cross-chain asset SOS, offering SWAP trading, launching LP staking mining pools, and introducing perpetual options with Bitcoin index inscriptions as premiums."
      },
      {
        time: "May 10, 2024",
        desc: "At the Bitcoin Asia Conference in Hong Kong, SmartBTC officially launched the BRC852 protocol for issuing new assets anchored to SOS, injecting new possibilities into the Bitcoin ecosystem."
      },
      {
        time: "Second Half of 2024",
        desc: "During the Token2049 conference in Singapore, SmartBTC.io launched version 2.0, continuing to integrate rich DeFi applications and gradually opening services to active Bitcoin inscriptions, making SOS one of the top 3 mainstream inscriptions by market cap."
      },
      {
        time: "2025",
        desc: "SmartBTC will gradually become compatible with mainstream BTC L2 and expand from SOS inscriptions to the entire Bitcoin ecosystem. BRC852 will become the mainstream asset issuance protocol, and SmartBTC.io will become a core application in the Bitcoin ecosystem, with SOS becoming the leading inscription."
      }
    ],
    myProject: "My Projects",
    revenueCollected: "Revenue Collected",
    receiveBenefits: "Claim Benefits",
    crossChainProgress: "Cross-chain Progress",
    LpProgress: "LP Swap Issuance Progress",
    KOLProgress: "KOL Reward Issuance Progress",
    progressDesc:
      "Note: Each time a new cross-chain or LP swap occurs, a new KOL reward will be triggered based on the corresponding proportion of the transaction. Certified KOLs will receive token airdrop rewards based on the increased allocation weight, which can be claimed at any time.",
    toExerciseRights: "Exercise Rights",
    options: "Options",
    unstake: "Unstake",
    unstakeDesc: "Unstaking the pledge will terminate the KOL qualification."
  },
  voting: {
    name: "Token Name",
    symbol: "Token Symbol",
    issuanceNumber: "Issuance Quantity",
    issuanceRatio: "Issuance Ratio",
    desc: "The project team (KOL) can refer to the SOS governance and buyback plan. During the project’s operational development, tokens circulating on the BSC chain can be continuously bought back and transferred into the cross-chain bridge contract, increasing the cross-chain capacity until 100%.",
    bridgeContract: "Cross-chain Bridge Contract",
    LPContract: "LP Swap Contract",
    startContract: "Startup Pool Contract",
    KOLContract: "KOL Allocation Contract",
    endTime: "End Time",
    voteProgress: "Voting Progress",
    voteHistory: "Voting History",
    voteBtn: "Vote in Support",
    voteTips: [
      "Current voting rules:",
      "Voting period is 7 days, with a threshold of 1 SOS, and 100 votes required to complete voting."
    ],
    voteSuccess: "Vote Successful"
  },
  noData: "No Data Available",
  noBalance: "Insufficient SOS Balance",
  copySuccess: " Copied successfully!",
  stakeSuccess: "Stake successfully!",
  kol: {
    title: [
      "KOL Certification — Social Accounts",
      "KOL Certification — Stake SOS",
      "KOL Certification — Become a Project Party"
    ],
    revenueAddress: "Revenue Address",
    twitter: "Twitter Address",
    desc1:
      "Post a Twitter certification tweet containing information about SmartBTC, SOS, and the inscription project you want to claim. The content is customizable, mainly focusing on the tweet's views, retweets, and likes within 24 hours.",
    telegram: "Telegram Address",
    discord: "Discord Address",
    submit: "Submit Certification",
    submitDesc:
      "KOL contribution weight calculation method: Initial weight mainly depends on three dimensions: total number of followers on social accounts, activity level of the certification tweet within 24 hours, and the actual amount of SOS staked. The platform will periodically collect data from certified social accounts (mainly Twitter, supplemented by Telegram and Discord) regarding their attention to and promotion of the claimed inscription projects and the SmartBTC platform, using algorithms to increase the KOL contribution weight.",
    kolReview: "KOL Review in Progress...",
    desposit: "Stake",
    search: "Search for Project Name",
    startPro: "Initiate Project",
    desc: [
      "Before claiming and creating a project, you need to stake SOS, starting from 2100. The more you stake, the higher the allocation weight. You can unstake at any time.",
      "Select the projects you support from the list below (fuzzy search supported) and click 'Claim' to bind. If the project you support is not yet listed on SmartBTC, you can 'Initiate Project', recommend a new inscription project, and launch a vote on the SmartBTC SOS Launchpad. If you receive 100 valid votes within 7 days, you will qualify for listing and automatically deploy the related contracts, while completing the KOL binding as the project party and automatically receive KOL rewards for the project."
    ],
    claim: "Claim",
    contentDesc: [
      "1. In principle, KOLs should maintain regular promotion of certain inscription projects through Twitter, Telegram, or by organizing offline activities to be recognized as community KOLs for the project;",
      "2. When submitting a claim, SmartBTC.io will comprehensively evaluate factors such as the amount of SOS staked by the KOL, the activity level of social accounts, the views, likes, and retweets of the certification tweet, the historical dynamics of social accounts related to the project, and the token holdings of the claimed address to automatically calculate the KOL's corresponding community airdrop reward allocation weight through algorithms;",
      "3. A KOL (corresponding to the certified wallet address) can only claim one project uniquely, and once claimed, it cannot be changed;",
      "4. Claims can be revoked at any time to recover the staked SOS. Once revoked, KOL rights will be terminated immediately and cannot be reapplied for reinstatement;",
      "5. After the claim is completed, KOLs should continue to promote the project actively and participate in community building. The SmartBTC.io platform's algorithms will periodically adjust their airdrop reward distribution rights based on the KOL's multidimensional dynamic data and may suspend or terminate the distribution rights of KOLs who do not participate in community building for an extended period."
    ],
    sure: "I have read and agree to the claiming rules and consent to claim this project.",
    claimRule: "Claim Rules",
    approve: "Authorize",
    despositSOS: "Stake SOS",
    balance: "Balance",
    inputNumber: "Please enter the staking amount",
    max: "Max",
    tweet:
      "My wallet {address} has staked SOS and is submitting KOL certification on the SmartBTC.io platform, participating in promoting the inscription of {name}. Please like and retweet this post to help {name} get trending on SmartBTC!",
    tweetTips: "Copy the above copy and tweet it as {'@'}smartbtcdao",
    status: [
      "Certification Approved",
      "Claimed or Created Project, Under Review...",
      "Pending KOL Contract Execution",
      "KOL Contract Executed",
      "Project KOL Activated",
      "Project KOL Setting Execution Failed",
      "Certification Review Not Passed",
      "Project KOL Set to Inactive"
    ],
    tips: [
      "Please enter a valid wallet address",
      "Please enter a valid X address",
      "Claim the {name} project",
      "Confirm if you have tweeted and submitted the application based on the current number of likes",
      "The staking amount must be greater than or equal to {minDeposit} SOS",
      "Certification application submitted"
    ],
    claimSuccess: "Claimed successfully"
  },
  kolAdd: {
    title: "KOL Certification — Recommend New Projects",
    SOSName: "Inscription Characters",
    SOSId: "Inscription Deployment ID",
    SOSSupply: "Total Inscription Supply",
    name: "SOS Inscription",
    symbol: "BSC Token Symbol",
    total: "Total BSC Tokens",
    rate: "Initial Allocation Ratio on BSC Chain",
    rateArr: [
      "Cross-Chain Contract",
      "Liquidity Issuance Contract",
      "Launch Pool",
      "KOL Reward Contract"
    ],
    submit: "Submit for Preliminary Review",
    desc: "Note: Once the preliminary review is passed, the project will enter the community voting phase. The effective voting period is 7 days, and SOS community members can participate in the voting to recommend the project. If the project receives 100 votes, it will gain listing rights, and related contracts will be automatically deployed.",
    tips: [
      "Please fill in the BSC token symbol",
      "Issue the {name} project",
      "Confirm if the tweet has been posted and submit the application based on the current number of likes"
    ],
    success: "Application Successful",
    error: "There is an error in the submitted information",
    descTitle: "Inscription Liquidity Innovation Solution",
    descNavTitle: [
      "Inspired by the PUMP.FUN model,",
      "creating liquidity pools for BTC mainnet inscriptions on L2 (BSC chain)."
    ],
    descTips: [
      "Certified inscription official communities can prioritize creating同名 projects on SmartBTC.io, launching with low market cap and fair distribution, allowing anyone to participate;",
      "When a project's market cap grows 10x (reaching approximately $50,000 USD), SmartBTC.io will open the LP addition function for that project, rapidly increasing liquidity;",
      "When the LP exchange progress reaches 30% (total LP reaching $50,000 USD), SmartBTC.io will open the cross-chain exchange function for that project, and the certified initiators and KOLs will jointly become the inscription project team;",
      "Certified KOLs can participate in creating any同名 inscription project, competing fairly based on two key metrics: token market cap growth and LP addition progress. The first token to meet the criteria becomes the official inscription project."
    ]
  },
  community: {
    title: [
      "My Hashrate",
      "Invited Hashrate",
      "Invitation Speed-Up",
      "Staked Hashrate",
      "Total Pool Hashrate"
    ],
    desc: [
      "*Each invited person contributes to the invited hashrate after completing a stake;",
      "*The invited hashrate can only provide actual returns when staked hashrate > 0;",
      "*The larger the staked hashrate, the higher the returns from the invited hashrate speed-up."
    ],
    btns: ["Share with Friends", "My Friends"],
    list: ["My Inviter", "Invited by Me", "No Friend Data", "My Friends"],
    share: [
      "1. Download the OKX Web3 Wallet",
      "2. In the 'Discover' channel, enter smartbtc.io in the search box",
      "3. CoinDAO members import the 100T wallet into the downloaded OKX, migrating cross-chain to the BSC chain; SOS users transfer cross-chain to BSC",
      "4. Participate in liquidity staking mining and become an options market maker (add liquidity pools), participate in options trading",
      "VC DAO focused on the Bitcoin ecosystem",
      "Copy and share the link"
    ],
    inviteSuccess: "Invitation Successful",
    revice: "Accept Invitation",
    reviceDesc: "Do you accept the invitation from {address}?"
  },
  pool: {
    search: "Please enter a name to search",
    goStaking: "Go Staking",
    scale: "Manage Scale",
    header: ["Total Liquidity Staked", "Real-time APY", "Contract"]
  },
  poolDetail: {
    goPancake: "Get LP from Pancakeswap",
    lp: "LP Address",
    min: "Minimum Staked Hashrate",
    search: "Enter the amount of LP to stake",
    search2: "Enter the amount of LP to use",
    getLp: "Please obtain LP first",
    balance: "Balance",
    tips: ["Current value of 1 LP", "Estimated staked hashrate gained"],
    approve: "Approve LP",
    stake: "Stake LP",
    unStake: "Redeem",
    desc: [
      "Redeemable after staking for 7 days;",
      "If not redeemed, the hashrate remains effective and continues to earn."
    ],
    contractAddress: "Contract Address",
    stakeSuccess: "Staking Successful",
    noLp: "Insufficient LP",
    approveSuccess: "Approve Successful",
    max: "Max",
    placeHolder: "This input requires ≥ 0"
  },
  withdraw: {
    title: "Claim Airdrop",
    btn: "Withdraw",
    history: "Recent Records",
    desc: [
      "Staked Hashrate",
      "Staked Block Height",
      "Redemption Block Height",
      "Redeem",
      "Pending Earnings",
      "Receive"
    ],
    allData: "All data loaded",
    withdrawSuccess: "Withdrawal Successful",
    ChargeSuccess: "Collection Successful",
    redemptionSuccess: "Redemption Successful"
  },
  lpSwap: {
    title: "Provide Liquidity to Issue Assets",
    desc: "Pegged to BRC20-{name}, total supply {total} K",
    desc2: "Pegged to Rune BRC20-(SATS ORDI SBTC), total supply {total} K",
    address: "Contract Address",
    shares: "My Holdings (Percentage)",
    navTitle: "Liquidity Issuance Exchange Progress",
    timely: "Real-time Exchange Rate",
    swap: "Go Swap",
    select: "Select Token",
    list1: [
      "Community Fair Minting",
      "Acquire CoinDAO",
      "LP Staking Mining",
      "Liquidity Issuance",
      "Launch Pool Deployment"
    ],
    list: ["Cross-Chain", "Liquidity Issuance", "Launch Pool", "KOL Rewards"],
    swapSuccess: "Swap Successful",
    swapFail: "Swap Failed",
    noBalance: "Insufficient Balance",
    errorTips: "Please enter the correct amount of LP",
    expected: "Expected to redeem"
  },
  btcSwap: {
    swap: "Cross-Chain",
    send: "Mint First, Then Send",
    buy: "Purchase",
    reviceAddress: "Receiving Address",
    paste: "Paste",
    submit: "Submit Cross-Chain",
    history: "Cross-Chain Records",
    select: "Select Minting",
    tips: "No mints available to send on {selectedChain}",
    allData: "All data loaded",
    connect: "Please connect your BTC wallet",
    desc: [
      "Please select the mint to send",
      "Please enter the correct receiving address"
    ],
    status: ["New", "In Progress", "Completed", "Failed", "Pending"],
    hash: "Transaction Hash",
    convertHash: "Convert Hash",
    amount: "Amount",
    orderStatus: "Order Status"
  },
  hecoSwap: {
    balance: "100T Balance",
    max: "Max",
    approve: "Approve",
    submit: "Submit Cross-Chain",
    bridge: "Cross-Chain",
    bridgeAmount: "Amount Available for Cross-Chain",
    tips: "Due to the acquisition by SmartBTC, CoinDAO community token 100T (HECO) can be exchanged at a 10:1 ratio for Bitcoin Rune IOOT•SMARTBTC•IO (the corresponding token on BSC is 100T). Please exercise your options as soon as possible!",
    history: "Cross-Chain Records",
    number: "Order Number",
    hash: "Transaction Hash",
    amount: "Amount",
    orderStatus: "Order Status",
    noBalance: "Insufficient Balance",
    approveSuccess: "Approve Successful",
    min: "Minimum cross-chain amount is {number}",
    submitSuccess: "Cross-Chain Submission Successful",
    placeHolder: "This input must be ≥ {number}"
  },
  options: {
    tradeTitle: "Trade",
    marketMakerTitle: "Market Maker",
    trade: {
      title: [
        "Index",
        "Premium",
        "Selection",
        "Available Margin",
        "Maintenance Margin",
        "Deposit",
        "Withdraw",
        "Trading Settings",
        "Slippage Limit",
        "Trading Deadline",
        "Minutes",
        "Long",
        "Short",
        "Amount",
        "Transaction Fee",
        "Fund Deposit",
        "Withdraw Funds",
        "Trade Information",
        "Options Type",
        "Open Position Amount",
        "Open Position Index"
      ],
      desc: "The premium will only be deducted when the available balance is less than the premium required for the next rollover.",
      max: "Max",
      select: "Select Token",
      search: "Enter Token Address to Search",
      nodata: "No Data",
      errorAddress: "Invalid Address",
      openFail: "Open Position Failed",
      openSuccess: "Open Position Successful",
      getSuccess: "Funds Retrieved Successfully",
      errorTips: ["Insufficient Balance", "Please Enter a Valid Amount"],
      desposit: "Funds Deposited Successfully",
      selectApproveToken: "Please Select an Authorized Token",
      tradeAmount: "24-Hour Trading Volume",
      approve: "Authorize",
      fee: "Transaction Fee",
      sure: "Confirm",
      open: "Open Position"
    },
    order: {
      title: "Order",
      have: "Current Position",
      tradeLP: "Trading Pair",
      type: "Options Type",
      openTime: "Open Time",
      openAmount: "Open Amount",
      UPL: "Unrealized Profit and Loss",
      long: "Long",
      short: "Short",
      openIndex: "Open Index",
      markIndex: "Mark Index",
      fee: "Transaction Fee",
      paid: "Premium Paid",
      close: "Close Position",
      history: "Historical Trades",
      amount: "Amount",
      profitAndLoss: "Profit and Loss",
      closeTime: "Close Time",
      closeInfo: "Close Position Information",
      positionAmount: "Position Amount",
      closingIndex: "Closing Index",
      sure: "Confirm",
      closeSuccess: "Close Position Successful"
    },
    info: {
      title: "Information",
      desc: [
        "Validity Date",
        "Perpetual",
        "Transaction Fee Rate",
        "Maintenance Margin Rate",
        "Type",
        "Perpetual Options",
        "Exercise",
        "Manual",
        "Risk Margin"
      ]
    },
    pool: {
      title: "Liquidity Pool",
      desc: [
        "Private Pool",
        "Available Liquidity",
        "Public Pool",
        "Available Liquidity"
      ]
    },
    marketMaker: {
      title: "Add Pool and Enjoy Market Maker Earnings",
      desc: [
        "Public Pool",
        "Earn premiums by providing reserve liquidity, open to all tokens.",
        "Private Pool",
        "Provide liquidity to sell perpetual options, open only to professional market makers."
      ]
    },
    public: {
      addLi: "Add Liquidity",
      select: "Select Token",
      tips: "For non-existent trading pairs, adding liquidity will automatically create the corresponding trading pair.",
      amount: "Amount",
      approve: "Approve",
      navTitle: "Your Share in the Pool",
      remove: "Remove",
      nodata: "No Data",
      search: "Enter Token Address to Search",
      removeLi: "Remove Liquidity",
      removeAmount: "Amount to Remove",
      max: "Max",
      willGet: "You will receive",
      selectApprove: "Please select the token to authorize",
      addLiSuccess: "Liquidity Added Successfully",
      errorTips: [
        "Please enter the correct amount to remove",
        "Insufficient Balance"
      ],
      successTips: "Liquidity Removed Successfully"
    },
    private: {
      setLiq: "Liquidity Set Successfully",
      applicationSet: "Application Settings",
      setPool: "Designate as Private Pool",
      getOrder: "Receive Orders",
      address: "Private Pool Address",
      setAddress: "Private Pool Settings",
      selecTradeLp: "Select Trading Pair",
      add: "Add",
      availability: "Availability Rate",
      yourLp: "Your Liquidity"
    }
  },
  sure: "Confirm",
  cancel: "Cancel",

  footer: {
    mine: "Create Project",
    bridge: "Exchange",
    swap: "LP Add",
    pool: "Startup Pool",
    community: "Share project"
  },
  noWallet: "Wallet not detected",
  connectTip: [
    "Please select Binance Smart Chain",
    "Please select Huobi Smart Chain"
  ],
  messageTip: [
    "Available only to the authenticated Inscription Community,",
    "your address is not authorized."
  ],
  connectWallet: "Connect Wallet",
  bindSuccess: "Binding Successful",
  bindText: [
    "Activate Inscription Airdrop Qualification",
    "Your linked BTC address",
    "Complete at least one LP addition to activate the qualification for receiving inscription airdrops and participate in airdrops regularly. All inscription ownerships involved in cross-chain exchanges belong to SOS platform token holders. Each airdrop will be distributed proportionally to the corresponding linked BTC address based on the daily weighted holdings of SOS."
  ],
  bindModal: [
    "Your BSC Address",
    "Please enter the BTC address to bind for receiving inscriptions"
  ],
  newData: {
    inscriptionMarketMaker: "Inscription Market Maker",
    memeTokenLaunch: "MEME Token Launch",
    token: "Token",
    totalSupply: "Total Supply",
    sameNameAsInscription: "Same Name as Inscription",
    equalAmountToInscription: "Equal Amount to Inscription",
    deployInscriptionId: "Deploy Inscription ID",
    tokenRatio: "Token Ratio",
    inscriptionCrossChain: "Inscription Cross-Chain",
    kolRewards: "KOL Rewards",
    lpAddition: "LP Addition",
    launchPool: "Launch Pool",
    launchPoolNote:
      "* The launch pool share is customized by the initiator and automatically transferred to their wallet upon token creation. It should be entirely used to create LP (Pancake V2, base tokens can be USDT, BNB, BTCB, or SOS). The LP should be fully converted into tokens (fully owned by the initiator).",
    tokenDescription: "Token Description",
    description: "Description",
    marketCap: "Market Cap",
    search: "Search",
    copy: "Copy",
    noData: "No Data",
    approve: "Approve"
  }
};
